import React from "react";

function DemandDeliveryLeadTime() {
  return (
    <div>
      <h1>hiiiii</h1>
    </div>
  );
}

export default DemandDeliveryLeadTime;
