import React from "react";

function Footer() {
  return (
    <div className="footer">
      Developed By <strong>DCG Datacore Systems Pvt. Ltd</strong>
      <br />
      <i>Version: 1.1.0</i>
    </div>
  );
}

export default Footer;
